import Icon from '@/components/Icon'
import { Check, InfoCircle, Warning } from '@/components/Icon/icons'
import clsx from '@/lib/utils/clsx'
import { cva } from 'class-variance-authority'

const bannerVariants = cva(
  'flex items-center justify-center rounded-md w-6 h-6 p-2xs outline-inner',
  {
    variants: {
      type: {
        info: 'bg-sunk outline-sunk text-primary',
        warning: 'bg-danger-container outline-danger-container text-danger',
        success: 'bg-success-container outline-success-container text-success',
      },
    },
  },
)

interface BannerProps {
  className?: string
  icon?: boolean
  type?: 'info' | 'warning' | 'success'
}

const Banner = ({
  children,
  className,
  icon = true,
  type = 'info',
}: React.PropsWithChildren<BannerProps>) => {
  const RenderIcon = () => {
    switch (type) {
      case 'info':
        return <InfoCircle />
      case 'warning':
        return <Warning />
      case 'success':
        return <Check />
      default:
        return <InfoCircle />
    }
  }
  return (
    <div
      className={clsx(
        'flex items-start gap-s rounded-md bg-sunk p-xs text-size-body text-primary',
        className,
      )}
    >
      {icon && (
        <div className={clsx(bannerVariants({ type }))}>
          <Icon size="md">{RenderIcon()}</Icon>
        </div>
      )}
      {children}
    </div>
  )
}

export default Banner
