import Button from '@/components/Button/Button'
import { ChevronRight, Google, Work } from '@/components/Icon/icons'
import store from '@/features/LoginPage/store'
import { observer } from 'mobx-react-lite'

const InitialStep = observer(() => {
  return (
    <div className="flex flex-col gap-2">
      <Button
        textClassName="grow text-start"
        size="lg"
        disabled={
          store.thirdPartyLoginInitData.state.apiCallStatus === 'loading'
        }
        iconLeft={Google}
        iconRight={ChevronRight}
        text="Continue with Google"
        onClick={() =>
          store.thirdPartyLoginInitData.fetch().then((_res) => {
            if (_res.apiCallStatus === 'success') {
              window.location.href = _res.data
            }
          })
        }
      />
      <Button
        size="lg"
        appearance="secondary"
        text="Continue with mail"
        textClassName="grow text-start"
        iconRight={ChevronRight}
        iconLeft={Work}
        onClick={() => store.setStep('login-with-email')}
      />
    </div>
  )
})

export default InitialStep
