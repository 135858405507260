const Scale: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="1em" height="1em" fill="none" viewBox="0 0 16 16" {...props}>
    <mask
      id="prefix__a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fill="currentColor"
        d="M11.2 11.2q-1.183 0-2.056-.74A3.18 3.18 0 0 1 8.048 8.6H4.683a1.66 1.66 0 0 1-.583.717 1.52 1.52 0 0 1-.9.283q-.672 0-1.136-.463A1.54 1.54 0 0 1 1.6 8.004q0-.67.464-1.137A1.54 1.54 0 0 1 3.2 6.4q.5 0 .9.283.4.284.591.717h3.376a3.2 3.2 0 0 1 1.085-1.86q.865-.74 2.048-.74 1.328 0 2.264.937a3.1 3.1 0 0 1 .936 2.267q0 1.329-.936 2.263a3.1 3.1 0 0 1-2.264.933m0-1.2q.834 0 1.417-.583Q13.2 8.833 13.2 8t-.583-1.417A1.93 1.93 0 0 0 11.2 6q-.834 0-1.417.583A1.93 1.93 0 0 0 9.2 8q0 .834.583 1.417.584.583 1.417.583"
      />
    </g>
  </svg>
)
export default Scale
