import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import EmailOtpStep from './components/EmailOtpStep'
import InitialStep from './components/InitialStep'
import { LoginLayout } from './components/LoginLayout'
import LoginWithEmailStep from './components/LoginWithEmailStep'
import store from './store'

const serverErrorMessages: Record<string, string> = {
  invalid_magic_link: 'Ooops! This link has expired. Please try again.',
}

export const LoginPage = observer(() => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const errCode = params.get('err_code')
    if (errCode && serverErrorMessages[errCode]) {
      store.setBannerMessage(serverErrorMessages[errCode]!)
    }
  }, [])

  const renderStep = () => {
    switch (store.step) {
      case 'initial':
        return <InitialStep />
      case 'login-with-email':
        return <LoginWithEmailStep />
      case 'email-otp':
        return <EmailOtpStep />
    }
  }

  return (
    <LoginLayout bannerMessage={store.bannerMessage}>
      {renderStep()}
    </LoginLayout>
  )
})
