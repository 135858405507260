import { URL_PATHS } from '@/app/url-constants'

export function redirectToLandingPage() {
  if (
    window.location.pathname !== URL_PATHS.home &&
    window.location.pathname !== URL_PATHS.loginWithMagicLink
  ) {
    window.location.assign(
      `${URL_PATHS.home}?redirect_uri=${window.location.pathname}`,
    )
  }
}
