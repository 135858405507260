import { useFeatureFlagEnabled } from 'posthog-js/react'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

type Features =
  | 'UNDO_REDO'
  | 'UNSPLASH'
  | 'COMMENTS'
  | 'DELETE_KEY'
  | 'MULTIPLE_CARDS'
  | 'EXECUTE_FLOW_SERVER_SIDE'
  | 'DESKTOP_RECOMMENDATION_PROMPT'
  | 'BOT_GENUI'
  | 'PRE_ANALYTICS'
  | 'FLOW_CANVAS'
  | 'AI_FLOW_GENERATION'
  | 'BILLING'
  | 'CODEGEN'

type FeatureFlags = {
  [key in Features]: boolean
}
const isLocalEnv = import.meta.env.MODE === 'mylocal'
const isDevEnv = import.meta.env.DEV

function getDefaultFeatureFlags(): FeatureFlags {
  return {
    UNDO_REDO: true,
    UNSPLASH: isLocalEnv,
    COMMENTS: isLocalEnv,
    DELETE_KEY: isLocalEnv,
    EXECUTE_FLOW_SERVER_SIDE: !isLocalEnv,
    MULTIPLE_CARDS: isLocalEnv,
    DESKTOP_RECOMMENDATION_PROMPT: !isLocalEnv,
    BOT_GENUI: isDevEnv,
    PRE_ANALYTICS: isLocalEnv,
    FLOW_CANVAS: isDevEnv,
    AI_FLOW_GENERATION: isLocalEnv,
    BILLING: !false,
    CODEGEN: isDevEnv,
  }
}

export const FeatureFlagContext = createContext<FeatureFlags>(
  getDefaultFeatureFlags(),
)

export const useFeatureFlags = () => useContext(FeatureFlagContext)

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const isUndoRedoEnabled = useFeatureFlagEnabled('undo-redo')
  const isCommentsEnabled = useFeatureFlagEnabled('comments')
  const isDeleteKeyEnabled = useFeatureFlagEnabled('delete-key')
  const isUnsplashEnabled = useFeatureFlagEnabled('UNSPLASH')
  const isPreAnalyticsEnabled = useFeatureFlagEnabled('pre-analytics')
  const isMultipleCardsEnabled = useFeatureFlagEnabled('multiple-cards')
  const isBotGenUIEnabled = useFeatureFlagEnabled('bot-genui')
  const isFlowCanvasEnabled = useFeatureFlagEnabled('flow-canvas')
  const isAiFlowGenerationEnabled = useFeatureFlagEnabled('ai-generate-flow')
  const isBillingEnabled = useFeatureFlagEnabled('billing')
  const isCodegenEnabled = useFeatureFlagEnabled('CODEGEN')

  const featureFlags = useMemo(() => {
    const defaultValues = getDefaultFeatureFlags()
    return {
      UNDO_REDO: isUndoRedoEnabled ?? defaultValues.UNDO_REDO,
      UNSPLASH: isUnsplashEnabled ?? defaultValues.UNSPLASH,
      COMMENTS: isCommentsEnabled ?? defaultValues.COMMENTS,
      DELETE_KEY: isDeleteKeyEnabled ?? defaultValues.DELETE_KEY,
      EXECUTE_FLOW_SERVER_SIDE: defaultValues.EXECUTE_FLOW_SERVER_SIDE,
      MULTIPLE_CARDS: isMultipleCardsEnabled ?? defaultValues.MULTIPLE_CARDS,
      DESKTOP_RECOMMENDATION_PROMPT:
        defaultValues.DESKTOP_RECOMMENDATION_PROMPT,
      BOT_GENUI:
        (isBotGenUIEnabled && isFlowCanvasEnabled) ?? defaultValues.BOT_GENUI,
      PRE_ANALYTICS: isPreAnalyticsEnabled ?? defaultValues.PRE_ANALYTICS,
      FLOW_CANVAS: isFlowCanvasEnabled ?? defaultValues.FLOW_CANVAS,
      AI_FLOW_GENERATION:
        isAiFlowGenerationEnabled ?? defaultValues.AI_FLOW_GENERATION,
      BILLING: isBillingEnabled ?? defaultValues.BILLING,
      CODEGEN: isCodegenEnabled ?? defaultValues.CODEGEN,
    }
  }, [
    isCommentsEnabled,
    isDeleteKeyEnabled,
    isUndoRedoEnabled,
    isUnsplashEnabled,
    isMultipleCardsEnabled,
    isBotGenUIEnabled,
    isPreAnalyticsEnabled,
    isFlowCanvasEnabled,
    isAiFlowGenerationEnabled,
    isBillingEnabled,
    isCodegenEnabled,
  ])

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
