import Button from '@/components/Button'
import { ChevronLeft, ChevronRight, Scale } from '@/components/Icon/icons'
import Input from '@/components/Input/Input'
import store from '@/features/LoginPage/store'
import validateEmail from '@/lib/utils/validations/validateEmail'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

const LoginWithEmailStep = observer(() => {
  const handleConfirm = useCallback(() => {
    const isValidEmail = validateEmail(store.email)
    if (isValidEmail) {
      store.reqEmailOtpApi.fetch().then((res) => {
        if (res.apiCallStatus === 'success') {
          store.setStep('email-otp')
        }
      })
    }
  }, [store])

  return (
    <div className="flex flex-col gap-3">
      <Input
        className="w-full"
        onChange={(e) => store.setEmail(e.target.value)}
        placeholder="Type here"
        value={store.email}
        iconRight={Scale}
        size="md"
      />
      <div className="flex gap-2">
        <Button
          size="lg"
          iconLeft={ChevronLeft}
          onClick={() => {
            store.setStep('initial')
          }}
        />
        <Button
          className="flex-grow justify-start"
          size="lg"
          text="Confirm"
          iconRight={ChevronRight}
          onClick={handleConfirm}
          disabled={store.reqEmailOtpApi.state.apiCallStatus === 'loading'}
        />
      </div>
    </div>
  )
})

export default LoginWithEmailStep
