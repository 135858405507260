import GlobalError from '@/components/GlobalError'
import { ErrorBoundary as DatadogErrorBoundary } from '@datadog/browser-rum-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { HotkeysProvider } from 'react-hotkeys-hook'
import App from './app/App'
import { FeatureFlagProvider } from './contexts/FeatureFlagContext'
import './index.css'
import { getInitialHotkeyScopes } from './lib/utils/hotkeys'
import { initTracing } from './tracing'

initTracing()
const queryClient = new QueryClient()

const options: Partial<PostHogConfig> = {
  api_host: import.meta.env['VITE_POSTHOG_HOST'],
  ui_host: 'https://us.posthog.com',
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      if (element?.classList.contains('disable-posthog-mask')) {
        return text
      }
      return '*'.repeat(text.length)
    },
  },
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env['VITE_POSTHOG_API_KEY']}
      options={options}
    >
      <FeatureFlagProvider>
        <HotkeysProvider initiallyActiveScopes={getInitialHotkeyScopes()}>
          <QueryClientProvider client={queryClient}>
            {import.meta.env['VITE_DATADOG_ENABLED'] === 'true' ? (
              // FYI this is an experimental package
              <DatadogErrorBoundary fallback={GlobalError}>
                <App />
              </DatadogErrorBoundary>
            ) : (
              <ErrorBoundary fallbackRender={GlobalError}>
                <App />
              </ErrorBoundary>
            )}
          </QueryClientProvider>
        </HotkeysProvider>
      </FeatureFlagProvider>
    </PostHogProvider>
  </React.StrictMode>,
)
