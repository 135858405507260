import {
  useFetchOrgBillingInfo,
  useFetchOrgList,
} from '@/api/org/useFetchOrgList'
import { namespaceStore } from '@/app/store/namespaceStore'
import { NamespaceType } from '@/features/Org/types/namespace'
import {
  GetBillingResponseDTO,
  Org,
  OrgPlanEntitlementDTO,
  ResourcePermissions,
  ResourcePermissionsAllowEnum,
  ResourcePermissionsDenyEnum,
} from '@thesysdev/coda-client'
import { useMemo } from 'react'
import userInfoStore from '../userInfoStore'

export type ExtendedOrg = Org & {
  allowedPermissions: ResourcePermissionsAllowEnum[]
  deniedPermissions: ResourcePermissionsDenyEnum[]
  billing: GetBillingResponseDTO
}

interface OrgPermission {
  orgId: string
  allowedPermissions: ResourcePermissionsAllowEnum[]
  deniedPermissions: ResourcePermissionsDenyEnum[]
}

/**
 * Extracts the orgs that the user is a member of from the userInfoStore permissions array
 *
 * @returns An array of orgs that the user is a member of
 */
export const useMaybeExtractUserOrgPermissions = ():
  | OrgPermission[]
  | undefined => {
  const maybePermissions = userInfoStore.maybePermissions
  const userOrgPermissionData = useMemo(
    () =>
      maybePermissions?.filter(
        (permission) => permission.resourceType === 'org',
      ),
    [maybePermissions],
  )

  const userOrgPermissions = useMemo<OrgPermission[] | undefined>(
    () => extractOrgPermissions(userOrgPermissionData),
    [userOrgPermissionData],
  )

  return userOrgPermissions
}

export const useExtractCurrentOrg = (): ExtendedOrg | null => {
  const orgSlug = getCurrentOrgSlug()
  const { data: userOrgs } = useFetchOrgList()
  const userOrgPermissions = useMaybeExtractUserOrgPermissions()
  const orgId = userOrgs?.find((org) => org.slug === orgSlug)?.id
  const { data: billing } = useFetchOrgBillingInfo({ orgId: orgId })

  const currentOrg: ExtendedOrg | null = useMemo(() => {
    if (!orgSlug || !userOrgs || !billing) return null
    const org = userOrgs.find((org) => org.slug === orgSlug)
    const orgPermissions = userOrgPermissions?.find(
      (orgPermission) => orgPermission.orgId === org?.id,
    )

    if (!org || !orgPermissions) return null

    return {
      ...org,
      allowedPermissions: orgPermissions.allowedPermissions,
      deniedPermissions: orgPermissions.deniedPermissions,
      billing,
    }
  }, [userOrgPermissions, orgSlug, userOrgs, billing])

  return currentOrg
}

export const extractOrgPermissions = (
  permissions: ResourcePermissions[] | undefined,
): OrgPermission[] => {
  if (!permissions) return []

  return permissions
    .filter((permission) => permission.resourceType === 'org')
    .map((permission) => ({
      orgId: permission.resourceId!,
      allowedPermissions: permission.allow,
      deniedPermissions: permission.deny,
    }))
}

export const getCurrentOrgSlug = (): string | null => {
  const activeNamespace = namespaceStore.activeNamespace
  if (!activeNamespace || activeNamespace.type !== NamespaceType.ORG)
    return null

  return activeNamespace.slug
}

export const useIsAiCreditExhausted = (): boolean => {
  const currentOrg = useExtractCurrentOrg()
  const aiCreditsEntitlement =
    currentOrg?.billing.subscription.entitlements.find(
      (e) => e.resourceKind === 'AiGenerationCredit',
    ) as OrgPlanEntitlementDTO | undefined
  return (
    (aiCreditsEntitlement?.usage ?? 0) >= (aiCreditsEntitlement?.amount ?? 0)
  )
}

export const useIsProjectLimitExhausted = (): boolean => {
  const currentOrg = useExtractCurrentOrg()
  const projectsEntitlement =
    currentOrg?.billing.subscription.entitlements.find(
      (e) => e.resourceKind === 'Project',
    ) as OrgPlanEntitlementDTO | undefined
  return (projectsEntitlement?.usage ?? 0) >= (projectsEntitlement?.amount ?? 0)
}
