import clsx from '@/lib/utils/clsx'
import { observer } from 'mobx-react-lite'
import { forwardRef } from 'react'

interface SeparatorProps {
  orientation?: 'horizontal' | 'vertical'
  className?: string
}

const Separator = observer(
  forwardRef<HTMLDivElement, SeparatorProps>(
    ({ orientation = 'horizontal', className }, ref) => {
      return (
        <div
          className={clsx(
            'flex items-center',
            {
              'h-1 w-full': orientation === 'horizontal',
              'h-full w-1 flex-col': orientation === 'vertical',
            },
            className,
          )}
          ref={ref}
        >
          <div
            className={clsx(
              {
                'h-full border-l': orientation === 'vertical',
                'w-full border-t': orientation === 'horizontal',
              },
              'border-primary-el',
            )}
          />
        </div>
      )
    },
  ),
)

export default Separator
