export const URL_PATHS = {
  home: '/home',
  login: '/login',
  loginWithMagicLink: '/login-with-magic-link',
  unauthenticatedWaitlist: '/unauthenticated-waitlist',
  publicPrototype: '/proto',
  publicPrototypeProjectId: `/proto/:projectId`,
  waitlist: '/waitlist',
  userSettings: '/settings/user',
  orgSettings: '/settings/org',
  projectCreate: 'project-create',
  editor: 'editor',
  prototype: 'prototype',
  project: '/project',
  projectWithProjectId: 'project/:projectId',
  orgCreate: 'org-create',
}

export type URL_PATHS_TYPE = keyof typeof URL_PATHS
