export const VIEW_QUERY_PARAM = 'view'
export const START_WITH_RESP_QUERY_PARAM = 'startWithResp'
export const INSIGHTS_QUERY_PARAM = 'insights'
export const SELECTED_THREAD_QUERY_PARAM = 'selected-thread'

export enum BotEditorViewId {
  BotFlows = 'flows',
  BotDefaults = 'defaults',
  Settings = 'settings',
  Prototype = 'prototype',
}
