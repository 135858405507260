import Banner from '@/components/Banner'
import CanvasLogo from '../assets/canvas-logo.png'
import ThesysCanvasGrid from '../assets/canvas-with-grid.png'
import Star from '../assets/star.png'
import styles from '../styles.module.css'

interface LoginLayoutProps {
  bannerMessage?: string
  children: React.ReactNode
}

export const LoginLayout = ({ bannerMessage, children }: LoginLayoutProps) => {
  return (
    <div className={styles['login-wrapper']}>
      <div className={styles['error-banner']}>
        {bannerMessage && (
          <Banner className="items-center bg-fill text-primary">
            {bannerMessage}
          </Banner>
        )}
      </div>
      <div className={styles['login-container']}>
        <div className={styles['splash-screen']}>
          <img className={styles['star-decoration-primary']} src={Star}></img>
          <img className={styles['star-decoration-secondary']} src={Star}></img>
          <div className={styles['splash-content']}>
            <div className={styles['splash-header']}>
              <div className={styles['logo-container']}>
                <img src={CanvasLogo}></img>
              </div>
              <div className={styles['main-heading']}>
                Product ideation tool for building AI agents
              </div>
            </div>
            <div className={styles['testimonial-section']}>
              <div className={styles['testimonial-quote']}>
                It&apos;s like having Figma for agents, <br />
                with simplicity of Miro and intelligence of v0.
              </div>
              <div className={styles['testimonial-author']}>Arnob, Olvy</div>
            </div>
          </div>
        </div>
        <div className={styles['login-form-wrapper']}>
          <div
            className={styles['mobile-logo-container']}
            id={styles['logo-mobile']}
          >
            <img className={styles['star-decoration-mobile']} src={Star}></img>
            <img
              className={styles['canvas-logo-grid']}
              src={ThesysCanvasGrid}
            ></img>
          </div>
          <div className={styles['login-form']}>
            {children}
            <div className="mt-4 text-center text-xs leading-5 text-secondary">
              For more information visit{' '}
              <a
                className="text-primary hover:text-link"
                href="https://www.thesys.dev"
                target="__blank"
              >
                thesys.dev
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
