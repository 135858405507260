import type { AxiosError } from 'axios'
import { ApiLinkedStore, api } from 'instrument'
import { makeAutoObservable } from 'mobx'

const genericApiError = 'Something went wrong.'

class Store {
  step: 'initial' | 'login-with-email' | 'email-otp' = 'initial'
  email = ''
  inputOtp = ''
  bannerMessage = ''
  resendOtpEnabled = false

  thirdPartyLoginInitData = new ApiLinkedStore(() =>
    api
      .thirdPartyControllerInitiateThirdPartyLogin({
        provider: 'google',
        state: 'sample_csrf_token',
      })
      .then((res) => ({ data: res.data.redirect_uri }))
      .catch((e) => {
        this.setBannerMessage(genericApiError)
        throw e
      }),
  )

  reqEmailOtpApi = new ApiLinkedStore(() => {
    return api
      .emailOtpControllerGenerateEmailOtp({
        email: this.email,
      })
      .then((res) => {
        this.setResendEnable(false)
        return { data: res.data }
      })
      .catch((e) => {
        this.setBannerMessage(genericApiError)
        throw e
      })
  })

  confirmEmailOtpApi = new ApiLinkedStore(() => {
    if (this.reqEmailOtpApi.state.apiCallStatus !== 'success') {
      this.setStep('initial')
      throw new Error('invalid state')
    }

    const { preAuthSessionId, deviceId } = this.reqEmailOtpApi.state.data
    const request = {
      preAuthSessionId,
      deviceId,
      userInputCode: this.inputOtp,
    }

    return api.emailOtpControllerUseOtp(request).catch((e: AxiosError) => {
      if (e.response?.status !== 400) {
        this.setBannerMessage(genericApiError)
      }
      throw e
    })
  })

  loginWithMagicLinkData = new ApiLinkedStore(
    ({ preAuth, linkCode }: { preAuth: string; linkCode: string }) =>
      api.magicLinkControllerUseMagicLinkV2(preAuth, linkCode).catch((e) => {
        this.setBannerMessage(genericApiError)
        throw e
      }),
  )

  constructor() {
    makeAutoObservable(this)
  }

  setStep = (step: typeof this.step) => {
    this.step = step
  }

  setEmail = (email: string) => {
    this.email = email
  }

  setInputOtp = (otp: string) => {
    this.inputOtp = otp
  }

  private bannerClearTimeout?: any

  setBannerMessage = (message: string) => {
    this.bannerMessage = message

    if (message === '') return

    this.bannerClearTimeout && clearTimeout(this.bannerClearTimeout)

    this.bannerClearTimeout = setTimeout(() => {
      this.setBannerMessage('')
    }, 5000)
  }

  private resendEnableTimeout?: any

  setResendEnable = (enable: boolean) => {
    this.resendOtpEnabled = enable

    if (!enable) {
      this.resendEnableTimeout && clearTimeout(this.resendEnableTimeout)
      this.resendEnableTimeout = setTimeout(() => {
        this.setResendEnable(true)
      }, 30000)
    }
  }
}

export default new Store()
