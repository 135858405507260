import authStore from '@/app/store/authStore'
import userInfoStore from '@/app/store/userInfoStore'
import Button from '@/components/Button'
import Icon from '@/components/Icon'
import {
  ChevronLeft,
  ChevronRight,
  InfoCircle,
  Refresh,
} from '@/components/Icon/icons'
import OTPInput from '@/components/OtpInput'
import store from '@/features/LoginPage/store'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const EmailOtpStep = observer(() => {
  const navigate = useNavigate()

  const isInvalidOtp =
    store.confirmEmailOtpApi.state.apiCallStatus === 'error' &&
    store.confirmEmailOtpApi.state.error?.response?.status === 400

  const handleConfirm = useCallback(() => {
    if (store.inputOtp.length === 6) {
      store.confirmEmailOtpApi.fetch().then(({ apiCallStatus }) => {
        if (apiCallStatus === 'success') {
          authStore
            .fetchAccessToken()
            .then(() => {
              userInfoStore.fetchUserInfo()
            })
            .finally(() => {
              navigate('/')
            })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, navigate])

  useEffect(() => {
    // clear otp on unmount
    return () => store.setInputOtp('')
  }, [])

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        {isInvalidOtp ? (
          <div className="flex items-center text-sm text-red">
            <Icon size="md" className="mr-1">
              <InfoCircle />
            </Icon>
            Oops! The code is invalid.
          </div>
        ) : (
          <div className="text-ellipsis text-sm text-black/30">
            OTP sent to <span className="text-black">{store.email}</span>
          </div>
        )}
        <Button
          size="sm"
          text="Resend"
          iconLeft={Refresh}
          onClick={() => {
            store.reqEmailOtpApi.fetch()
          }}
          appearance="text"
          disabled={
            !store.resendOtpEnabled ||
            store.reqEmailOtpApi.state.apiCallStatus === 'loading'
          }
        />
      </div>
      <OTPInput
        className="w-full"
        onChange={({ target: { value } }) => store.setInputOtp(value)}
        maxLength={6}
      />
      <div className="flex gap-2">
        <Button
          size="lg"
          iconLeft={ChevronLeft}
          onClick={() => {
            store.setStep('initial')
          }}
        />
        <Button
          className="flex-grow justify-start"
          size="lg"
          text="Confirm"
          iconRight={ChevronRight}
          onClick={handleConfirm}
          disabled={store.confirmEmailOtpApi.state.apiCallStatus === 'loading'}
        />
      </div>
    </div>
  )
})

export default EmailOtpStep
