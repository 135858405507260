import Icon from '@/components/Icon'
import clsx from '@/lib/utils/clsx'
import React, { ChangeEventHandler } from 'react'

interface InputProps {
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  className?: string
  disabled?: boolean
  placeholder?: string
  iconLeft?: React.FC<React.SVGProps<SVGSVGElement>>
  iconRight?: React.FC<React.SVGProps<SVGSVGElement>>
  size?: 'md' | 'lg' | 'sm'
  name?: string
  value?: string
  variant?: 'primary' | 'secondary' // added border prop to remove border
  maxLength?: number
  multiline?: boolean
  type?: 'text' | 'number'
  defaultValue?: string
  label?: string
  autoFocus?: boolean
  slotLeft?: React.ReactNode
  slotRight?: React.ReactNode
}

const Input = ({
  onChange,
  onBlur,
  onKeyDown,
  className,
  disabled,
  placeholder,
  iconRight: IconRightSvg,
  iconLeft: IconLeftSvg,
  size = 'sm',
  name,
  value,
  variant = 'primary',
  maxLength,
  multiline,
  type = 'text',
  defaultValue,
  label,
  autoFocus,
  slotLeft,
  slotRight,
}: InputProps) => {
  const [isActive, setIsActive] = React.useState<boolean>(false)
  const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)

  const commonProps: Partial<
    React.HTMLProps<HTMLInputElement> | HTMLTextAreaElement
  > = {
    disabled,
    placeholder,
    className: clsx(
      'w-full bg-transparent text-size-primary text-primary outline-none placeholder:text-secondary disabled:cursor-not-allowed resize-none',
      '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
    ),
    onFocus: () => setIsActive(true),
    onBlur: (e) => {
      setIsActive(false)
      onBlur && onBlur(e)
    },
    onKeyDown: (e) => {
      onKeyDown && onKeyDown(e)
    },
    name,
    value,
    maxLength,
    autoFocus,
  }

  return (
    <div
      className={clsx(
        'inline-flex w-60 cursor-text items-center gap-xs rounded-md',
        'bg-primary-el outline-primary-el ',
        {
          'outline-0': variant === 'secondary',
          'outline-inner': variant === 'primary',
          'p-s': size === 'lg',
          'px-s py-xs': size === 'md',
          'px-s py-2xs': size === 'sm',
          'bg-sunk outline-selected': isActive,
          'cursor-not-allowed bg-black/4 outline-sunk': disabled,
          //standard for all disabled inputs is be decided by the design team.
          //this is just a placeholder for now.
        },
        className,
      )}
      role="textbox"
      tabIndex={disabled ? undefined : 0}
      onFocus={() => {
        inputRef.current?.focus()
      }}
    >
      {slotLeft
        ? slotLeft
        : IconLeftSvg && (
            <Icon size="md" className="mr-1 text-black/30">
              {<IconLeftSvg />}
            </Icon>
          )}
      {multiline ? (
        <textarea
          onChange={onChange}
          ref={inputRef as React.RefObject<HTMLTextAreaElement>}
          rows={3}
          {...(commonProps as React.HTMLProps<HTMLTextAreaElement>)}
        />
      ) : (
        <input
          onChange={onChange}
          ref={inputRef as React.RefObject<HTMLInputElement>}
          type={type}
          defaultValue={defaultValue}
          {...commonProps}
        />
      )}
      {label && <div className="text-xs">{label}</div>}
      {slotRight
        ? slotRight
        : IconRightSvg && (
            <Icon size="md" className="ml-0.5 text-black/30">
              {<IconRightSvg />}
            </Icon>
          )}
    </div>
  )
}

export default Input
