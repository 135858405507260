const Work = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.4 5.13333V4.06667H7.26667V5.13333H9.4ZM4.06667 6.2V12.0667H12.6V6.2H4.06667ZM12.6 5.13333C13.192 5.13333 13.6667 5.608 13.6667 6.2V12.0667C13.6667 12.6587 13.192 13.1333 12.6 13.1333H4.06667C3.47467 13.1333 3 12.6587 3 12.0667L3.00533 6.2C3.00533 5.608 3.47467 5.13333 4.06667 5.13333H6.2V4.06667C6.2 3.47467 6.67467 3 7.26667 3H9.4C9.992 3 10.4667 3.47467 10.4667 4.06667V5.13333H12.6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Work
