import Separator from '@/components/Separator'
import clsx from '@/lib/utils/clsx'
import { OTPInput as DefaultOTPInput, SlotProps } from 'input-otp'
import { useCallback, useState } from 'react'
import styles from './styles.module.css'

const Slot = (props: SlotProps & { isLast: boolean }) => {
  return (
    <>
      <div
        className={clsx(
          'flex w-8 items-center justify-center text-size-primary',
        )}
      >
        {props.char !== null && <div>{props.char}</div>}
        {props.hasFakeCaret && (
          <div className={styles['animated-caret']}>|</div>
        )}
      </div>
      {!props.isLast && (
        <Separator
          orientation="vertical"
          className="h-6 justify-center py-3xs"
        />
      )}
    </>
  )
}

interface ChangeEvent {
  target: {
    name?: string
    value: string
  }
}

export interface OTPInputProps {
  name?: string
  onChange: (event: ChangeEvent) => void
  value?: string
  maxLength: number
  className?: string
  disabled?: boolean
}

const OTPInput = ({
  name,
  value,
  onChange,
  maxLength,
  className,
  disabled,
}: OTPInputProps) => {
  const [isActive, setIsActive] = useState(false)

  const handleOnChange = useCallback((e: string) => {
    onChange({
      target: {
        name,
        value: e,
      },
    })
  }, [])

  return (
    <DefaultOTPInput
      onChange={handleOnChange}
      containerClassName={clsx(
        'inline-flex outline-inner outline-default  py-xs px-s rounded-md hover:bg-sunk justify-center text-primary',
        { 'outline-inner outline-selected': isActive, 'bg-sunk': disabled },
        className,
      )}
      className="disabled:cursor-not-allowed"
      value={value}
      render={({ slots }) =>
        slots.map((slot, index) => (
          <Slot key={index} {...slot} isLast={index === slots.length - 1} />
        ))
      }
      maxLength={maxLength}
      disabled={disabled}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
    />
  )
}

export default OTPInput
